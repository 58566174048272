<template>
  <v-app>
    <v-app-bar app color="orange darken-4" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/icon.png"
          transition="scale-transition"
          width="40"
        />

        <v-app-bar-title class="shrink mt-1 hidden-sm-and-down"
          >LNdev.co</v-app-bar-title
        >
      </div>

      <v-spacer></v-spacer>

      <v-container>
        <v-row justify="center">
          <v-col md="8">
            <v-tabs fixed-tabs background-color="orange darken-4" dark>
              <v-tab @click="dydns()"> Dynamic Dns </v-tab>
              <v-tab @click="staticip"> Static IP </v-tab>
              <v-tab @click="vps"> VPS </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>

      <v-spacer></v-spacer>

      <v-btn text @click="domain">
        <span class="mr-2">Buy SubDomain</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container v-if="content == 'landing'">
        <Landing />
      </v-container>
      <v-container v-if="content == 'dydns'">
        <Dydns />
      </v-container>
      <v-container v-if="content == 'staticip'">
        <Static />
      </v-container>
      <v-container v-if="content == 'vps'">
        <Vps />
      </v-container>
      <v-container v-if="content == 'domain'">
        <Domain />
      </v-container>
      <v-row v-if="content !== 'domain'" justify="center">
        <v-btn @click="content = 'domain'">
          <span>Buy a subdomain</span>
        </v-btn>
      </v-row>
      <v-row padding-bottom="150px">
        <v-spacer></v-spacer>
      </v-row>
    </v-main>
    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>LNdev.co</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import Landing from "./components/Landing";
import Dydns from "./components/Dydns";
import Static from "./components/Static";
import Vps from "./components/Vps";
import Domain from "./components/Domain";

export default {
  name: "App",

  components: {
    Landing,
    Dydns,
    Static,
    Vps,
    Domain,
  },

  data: () => ({
    content: "landing",
    //
  }),

  methods: {
    landing() {
      this.content = "landing";
    },
    dydns() {
      this.content = "dydns";
      console.log(this.content);
    },
    staticip() {
      this.content = "staticip";
    },
    vps() {
      this.content = "vps";
    },
    domain() {
      this.content = "domain";
    },
  },
};
</script>
