<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Purchase a subdomain</h1>

        <p class="subheading font-weight-regular">
          Please read the guides first to find out what record you require
          <br />please join our online
          <a href="https://community.vuetifyjs.com" target="_blank"
            >Telegram Chanel</a
          >
        </p>
      </v-col>
      <v-col cols="12">
        <v-card v-if="domain == 'choose'" class="mx-auto" max-width="400" tile>
          <v-subheader>Choose a domain</v-subheader>

          <v-list-item
            href="https://lnbits.lndev.co/subdomains/cdAp3addvUCfa5aWpV4ZQc"
          >
            <v-list-item-content>
              <v-list-item-title>lndev.co</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="orange darken-4"> mdi-cart </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            href="https://lnbits.lndev.co/subdomains/hhS4LCex2mn747bDGgLuM6"
          >
            <v-list-item-content>
              <v-list-item-title>satshopper.co</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="orange darken-4"> mdi-cart </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            href="https://lnbits.lndev.co/subdomains/QTQ65TrLaVvVdo9tAoqDQb"
          >
            <v-list-item-content>
              <v-list-item-title>bitcoinblogz.online</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="orange darken-4"> mdi-cart </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Domain",

  data: () => ({
    domain: "choose",
  }),
  methods: {
    setLndev() {
      this.domain = "lndev";
    },
    setSatshopper() {
      this.domain = "satshopper";
    },
    setBitcoinblogz() {
      this.domain = "bitcoinblogz";
    },
    cancel() {
      this.domain = "choose";
    },
  },
};
</script>
