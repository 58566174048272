<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">VPS Setup</h1>

        <p class="subheading font-weight-regular">Coming Soon</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Vps",

  data: () => ({
    //
  }),
};
</script>
