<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Welcome to LNdev.co</h1>

        <p class="subheading font-weight-regular">
          Follow our guides to point a subdomain to your node
        </p>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Caution</h2>

        <p class="subheading font-weight-bold">
          If you do this you are opening your node to the clearnet
          <br />You do this at your own risk
        </p>
        <p>
          Set up your firewall carefully before you attempt to setup any
          connections
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Landing",

  data: () => ({
    //
  }),
};
</script>
