<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Static Ip Setup</h1>

        <p class="subheading font-weight-regular">
          You will need to obtain a static IP from your internet service
          provider, They may charge you for this service.
          <br />Once you have recived your static ip from your isp come back to
          this guide to get started.
        </p>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Login to your router</h2>

        <v-spacer></v-spacer>

        <v-row justify="center">
          <p class="subheading font-weight-regular">
            The first thing to do it to login to the admin pannel of your router
            <br />You can usually do this in a browser on your local network at
            192.168.0.1.or 192.168.1.1 <br />If you have not already set your
            own, the password is usually on a printed on a sticker on your
            router with the username being admin
          </p>
        </v-row>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">
          Give your node a static local ip
        </h2>

        <v-spacer></v-spacer>

        <v-row justify="center">
          <p class="subheading font-weight-regular">
            In your router admin pannel, maybe in the advanced settings, you
            need to give your node a static ip address
            <br />Check the ip your node currently has and set this as the fixed
            ip, this will ensure you node is always available at this address
          </p>
        </v-row>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">
          Set up a firewall on your node.
        </h2>

        <v-spacer></v-spacer>

        <v-row justify="center">
          <p class="subheading font-weight-regular">
            You will be opening up your node to the outside world so it is
            importand to not overexpose yourself
            <br />You only want to open the ports for the applications you need
            to expose to the clearnet <br />login to your node, install UFW and
            block all but the required ports
          </p>
        </v-row>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Setup router DMZ</h2>

        <v-spacer></v-spacer>

        <v-row justify="center">
          <p class="subheading font-weight-regular">
            The DMZ is a feature of many routers, This allows you to route all
            outside traffic to a specified ip address
            <br />Look for the DMZ feature in your router and point it towards
            the local static ip of your node that we set up earlier <br />Now
            any outside connections will hit your node.
          </p>
        </v-row>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Get a subdomain</h2>

        <v-spacer></v-spacer>

        <v-row justify="center">
          <p class="subheading font-weight-regular">
            Choose your required subdomain, select an A record and point it to
            the ip address you recived from your isp
          </p>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Static",

  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer",
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
      },
      {
        text: "Frequently Asked Questions",
        href:
          "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
};
</script>
